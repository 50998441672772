import { Link } from 'gatsby'
import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'

// https://www.dktech.cz/?page_id=24

const OServeru: FC = () => {
  return (
    <PageTemplate>
      <div className="topTitle">
        <h1><Link to="/o_serveru">O serveru</Link></h1>
      </div>
      <h3><strong style={{ fontSize: '2em' }}>Start serveru C4: 24. 12. 2013&nbsp;</strong></h3>
      <h1><strong style={{ fontSize: '13px' }}>Rates:</strong></h1>
      <blockquote>
        <p>Exp rate: 2x<br />
          Adena rate: 2x<br />
          Quest reward rate: 2x<br />
          Quest items drop rate: 2x<br />
          Item drop rate / spoil rate: 2x<br />
          World Boss jewellery drop rate: 1x<br />
          <span style={{ fontSize: '13px' }}><br />
          </span></p></blockquote>
      <p><strong>Obecný popis:</strong></p>
      <blockquote><p>Stabilní server, bez lagů a pádů.<br />
        Výkonný server hardware pro více než 2000 hráčů.<br />
        Rychlé a stabilní internetové připojení.<br />
        Pravidelné restarty a údržba.<br />
        Žádné donate!<br />
        Ochrana serveru proti používání botů a cheatů.<br />
        Bez omezení počtu herních klientů.</p></blockquote>
      <p><strong>Odkazy:</strong></p>
      <blockquote>
        <p>
          <Link to="/kontakty">Aktuální gm a admin tým + kontakty:</Link><br />
          <a href="https://dktech.cz/links/registration.php" target="_blank">Registrace herního účtu:</a><br />
          <Link to="/pravidla">Pravidla serveru:</Link><br />
          <a href="https://dktech.cz/forum/index.php" target="_blank">Diskusní fórum:&nbsp;</a>
        </p>
      </blockquote>
      <div>
        <div>Be Sociable, Share!</div>
      </div>
      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default OServeru

export { Head } from '../components/defaultHtmlHead'